import React, { useState } from "react"
import { Container } from "reactstrap"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Title from "../Title"
import StyledHero from "../StyledHero"
import { FiDownload } from "react-icons/fi"
import { richTextOptions } from "../../utils/richTextRender";

const getBioData = graphql`
  query {
    allContentfulBiography {
      nodes {
        engBio {
          json
        }
      }
      edges {
        node {
          germanContent {
            germanContent
          }
        }
      }
    }
    banner: file(relativePath: { eq: "bio-banner.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Biography = () => {
  const [german, setGerman] = useState(false)
  const toggleGerman = () => {
    setGerman(german => !german)
  }
  const data = useStaticQuery(getBioData)
  const {
    germanContent: { germanContent },
  } = data.allContentfulBiography.edges[0].node
  const { engBio } = data.allContentfulBiography.nodes[0]

  return (
    <BiographyWrapper>
      <StyledHero img={data.banner.childImageSharp.fluid} />
      <section className="py-5" style={{ background: "var(--bgGrey)" }}>
        <Container>
          <Title title="Biography" />
          <hr />
          <h6 className="text-center">Download:</h6>
          <div className="d-flex justify-content-center mb-1">
            <div>
              <div>
                <a
                  href="jun-maerkl-bio-eng.pdf"
                  download="jun-maerkl-bio-eng.pdf"
                >
                  <FiDownload /> English
                </a>
              </div>
              <div>
                <a
                  href="jun-maerkl-bio-eng-short.pdf"
                  download="jun-maerkl-bio-eng-short.pdf"
                >
                  <FiDownload /> English short
                </a>
              </div>
              <div>
                <a
                  href="jun-maerkl-bio-ger.pdf"
                  download="jun-maerkl-bio-ger.pdf"
                >
                  <FiDownload /> German
                </a>
              </div>
            </div>
          </div>
          <div className="my-3 text-center">
            <button className="junbutton btn" onClick={toggleGerman}>
              {german ? "Switch to English" : "Switch to German"}
            </button>
          </div>
          <div className="content-box py-1 px-3 px-md-5">
            {german ? (
              <p className="english">{germanContent}</p>
            ) : (
              <p className="german">{documentToReactComponents(engBio.json, richTextOptions)}</p>
            )}
          </div>
        </Container>
      </section>
    </BiographyWrapper>
  )
}

const BiographyWrapper = styled.div`
  /* .german {
    ${props => (props.german ? "display: none" : "display: block")};
  } */
  a {
    color: var(--primaryColor);
    letter-spacing: var(--mainSpacing);
  }
`

export default Biography
